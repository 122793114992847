.addReportButton {
	background: #f7901d;
	border-radius: 3px;
	padding: 5px 25px;
	margin-bottom: 5px;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}
