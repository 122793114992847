.editIcon {
	cursor: pointer;
	color: rgb(37, 200, 241);
	font-size: 16px;
	font-weight: bold;
}

.viewIcon {
	cursor: pointer;
	color: rgb(37, 200, 241);
	font-size: 16px;
	font-weight: bold;
}

.remarksText {
	cursor: pointer;
	font-size: 12px;
}
.deleteIcon {
	cursor: pointer;
	padding-top: 5px;
	color: rgb(37, 200, 241);
	font-size: 18px;
	font-weight: bold;
	margin-left: 8px;
}

.table {
	margin: 5px;
	background-color: #c8edf6;
}

.Heading {
	display: flex;
	background-color: #c8edf6;
	padding: 60px 10px 10px 10px;
	@media screen and (min-width: 800px) {
		padding: 40px 5px 5px 5px;
	}
	@media screen and (min-width: 1024px) {
		padding: 50px 5px 5px 5px;
	}
	@media screen and (min-width: 1280px) {
		padding: 60px 5px 5px 5px;
	}
	@media screen and (min-width: 1360px) {
		padding: 60px 5px 5px 5px;
	}

	@media screen and (min-width: 1620px) {
		padding: 120px 20px 10px 20px;
	}
}
.leftHeader {
	display: flex;
	justify-content: space-between;
}

.homeIconContainer {
	display: flex;
	justify-content: right;
}
.text {
	font-weight: bold;
	color: rgb(37, 200, 241);
}
.pendingRequest {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	background-color: red;
}

.rightHeader {
	display: flex;
	justify-content: right;
}
