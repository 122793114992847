.container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	border: 1px solid #e6e2e1;
	border-radius: 15px;
	margin-bottom: 7px;
}

.input {
	font-size: 12px;
	color: #c6c6c6;
}

.searchIcon {
	background-color: #ffffff;
	cursor: pointer;
	padding: 4px;
	border-radius: 15px;
	color: #cccccc;
}

.closeButton {
	font-size: 14px;
	color: #312e2d;
	font-weight: 800;
	padding: 5px;
	cursor: pointer;
}
