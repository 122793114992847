.autoComplete {
	width: 250px;
}

.formContainer {
	background-color: white;
	margin: 0px 20px 10px 20px;
	border-radius: 5px;
}

.headingContainer {
	padding: 25px 30px 0px 5px;
	border-bottom: 1px solid #707070;
	min-height: 30px;
	max-height: 60px;
}

.header {
	line-height: 10px;
	font-weight: bold;
	font-size: 18px;
	color: #312e2d;
}
.closeIconContainer {
	color: #1c7eee;
	display: flex;
	justify-content: right;
}
.closeIcon {
	cursor: pointer;
	color: red;
	font-size: 15px;
}

.textFieldContainer {
	margin: 10px 20px 0px 20px;
}

.nameField {
	display: flex;
	justify-content: left;
}
.emailField {
	display: flex;
	justify-content: left;
}

.buttonContainer {
	margin: 0px 20px 0px 20px;
	padding: 40px 0px 0px 0px;
	display: flex;
	justify-content: flex-end;
}

.submitButton {
	height: 30px;
}
.clearButton {
	height: 30px;
}
