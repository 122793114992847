.sidebarContainer {
	background-color: #312e2d;
	font: 12px;
	margin-top: 60px;
	/*1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		margin-top: 42px;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 50px;
		padding-top: 5px;
	}
	@media screen and (min-width: 1280px) {
		background-color: #312e2d;
		font: 12px;
		margin-top: 60px;
		padding-top: 12px;
	}
	@media screen and (min-width: 1620px) {
		font: 12px;
		margin-top: 120px;
		padding-top: 12px;
	}
	@media screen and (min-width: 1920px) {
		font: 12px;
		margin-top: 120px;
		padding-top: 12px;
	}
}

.sidebar {
	display: flex;
	flex-direction: column;
	height: 551px;
	width: 220px;
	margin-top: 10px;
	/*1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		width: 125px;
		height: 400px;
	}
	@media screen and (min-width: 1024px) {
		height: 560px;
		width: 165px;
	}
	@media screen and (min-width: 1280px) {
		height: 551px;
		width: 220px;
		margin-top: 10px;
	}
	@media screen and (min-width: 1620px) {
		height: 860px;
		width: 337px;
		margin-top: 20px;
	}
	@media screen and (min-width: 1920px) {
		height: 920px;
		width: 337px;
		margin-top: 20px;
	}
}
