.glossaryStyle {
	max-height: 561px;
	width: 1140px;
	@media screen and (min-width: 800px) {
		width: 670px;
	}
	@media screen and (min-width: 1024px) {
		width: 820px;
	}
	@media screen and (min-width: 1280px) {
		width: 1010px;
	}
	@media screen and (min-width: 1360px) {
		width: 1140px;
	}
	@media screen and (min-width: 1620px) {
		width: 1370px;
	}
	@media screen and (min-width: 1920px) {
		width: 1700px;
	}
	@media screen and (min-width: 2200px) {
		width: 90vw;
	}
}

.glossaryBody {
	margin: 60px 0px 0px 220px;
	background-color: #f3f3f3;
	@media screen and (min-width: 800px) {
		margin: 42px 0px 0px 125;
	}
	@media screen and (min-width: 1024px) {
		margin: 50px 0px 0px 165px;
	}
	@media screen and (min-width: 1280px) {
		margin: 50px 0px 0px 220px;
	}
	@media screen and (min-width: 1360px) {
		margin: 60px 0px 0px 220px;
	}
	@media screen and (min-width: 1620px) {
		margin: 120px 0px 0px 337px;
	}
	@media screen and (min-width: 1920px) {
		margin: 120px 0px 0px 337px;
	}
	@media screen and (min-width: 2200px) {
		margin: 120px 0px 0px 337px;
	}
}

.firstline {
	margin: 18px 15px 0px 15px;
	border-bottom: 2px solid #707070;
}
.closeButton {
	font-size: 14px;
	color: #eb9995;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #eb9995;
	border-radius: 50%;
	margin-bottom: 10px;
}

.left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.glossary {
	font-size: 18px;
	font-weight: bold;
	color: #312e2d;
	margin-bottom: 10px;
}

.tableContainer {
	background-color: white;
	margin: 10px 20px 0px 20px;
	padding: 11px 11px 10px 11px;
	border-radius: 5px;
}

.table {
	background-color: white;
	max-height: 560px;
	@media screen and (min-width: 800px) {
		max-height: 400px;
	}
	@media screen and (min-width: 1024px) {
		max-height: 560px;
	}
	@media screen and (min-width: 1280px) {
		max-height: 560px;
	}
	@media screen and (min-width: 1360px) {
		max-height: 560px;
	}
	@media screen and (min-width: 1620px) {
		max-height: 800px;
	}
	@media screen and (min-width: 1920px) {
		max-height: 860px;
	}
	@media screen and (min-width: 2200px) {
		max-height: 920px;
	}
}

.tableCell {
	color: #707070;
	font-size: 12px;
}

.tableCellHeader {
	font-size: 12px;
	color: #312e2d;
}

.highlighterText {
	background-color: #f7901d;
}
