.tabs-container {
	border: 1px solid #f7f3ef;
	border-radius: 5px;
	overflow: auto;
}

.tabs {
	display: flex;
	justify-content: center;
	background-color: rgb(200, 237, 246);
	border-radius: 5px;
	padding: 0.25rem;
}

.tab {
	padding: 0.25rem;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.264);
	min-width: 200px;
	display: flex;
	justify-content: center;
	text-align: center;
	transition: background-color 0.3s ease;
	background-color: white;
	align-items: center;
}

.tab:hover {
	background-color: #f7f3ef;
}

.tab.active {
	background-color: #f7901d;
	color: white;
}

.tab-content {
	padding: 0.25rem;
	margin: 0.1rem;
}

.text {
	font-weight: bold;
	background-color: #f7901d;
	color: white;
	padding: 0.2rem;
}

.text:hover {
	background-color: #f7901d;
	color: white;
}
