.appbar {
	background-color: #312e2d;
	height: 60px;
	@media screen and (min-width: 800px) {
		background-color: #312e2d;
		height: 42px;
	}
	@media screen and (min-width: 1024px) {
		background-color: #312e2d;
		height: 50px;
	}
	@media screen and (min-width: 1280px) {
		background-color: #312e2d;
		height: 60px;
	}
	@media screen and (min-width: 1360px) {
		background-color: #312e2d;
		height: 60px;
	}

	@media screen and (min-width: 1620px) {
		background-color: #312e2d;
		height: 120px;
	}
}

.leftHeader {
	display: flex;
	justify-content: left;
}

.onedataicon {
	width: 75px;
	height: 58px;
	margin-top: 5px;
	@media screen and (min-width: 800px) {
		width: 45px;
		height: 35px;
		margin-top: 5px;
		margin-left: 25px;
	}
	@media screen and (min-width: 1024px) {
		width: 60px;
		height: 45px;
		margin-top: 5px;
		margin-left: 0px;
	}
	@media screen and (min-width: 1280px) {
		width: 75px;
		height: 58px;
		margin-top: 5px;
	}
	@media screen and (min-width: 1360px) {
		width: 75px;
		height: 58px;
		margin-top: 5px;
	}
	@media screen and (min-width: 1620px) {
		width: 120px;
		height: 95px;
		margin-top: 10px;
	}
}

.remarks {
	color: #ffffff;
	width: 16.4px;
	height: 19px;
	cursor: pointer;
	padding-right: 10px;
	margin-top: 15px;
	border-right: 1px solid #ffffff40;
}
@media screen and (min-width: 800px) {
	body .remarks {
		color: #ffffff;
		width: 11.4px;
		height: 14px;
		cursor: pointer;
		margin-top: 12px;
	}
}
@media screen and (min-width: 1024px) {
	body .remarks {
		color: #ffffff;
		width: 15.4px;
		height: 18px;
		cursor: pointer;
		margin-top: 12px;
	}
}
@media screen and (min-width: 1280px) {
	body .remarks {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		cursor: pointer;
		margin-top: 15px;
	}
}
@media screen and (min-width: 1360px) {
	body .remarks {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		cursor: pointer;
		margin-top: 15px;
	}
}
@media screen and (min-width: 1620px) {
	body .remarks {
		color: #ffffff;
		width: 30px;
		height: 36px;
		cursor: pointer;
		margin-right: 10px;
		margin-top: 28px;
	}
}

.remarksHover {
	color: #f7901d;
	width: 16.4px;
	height: 19px;
	padding-right: 10px;
	margin-top: 15px;
	cursor: pointer;
	border-right: 1px solid #ffffff40;
}

@media screen and (min-width: 800px) {
	body .remarksHover {
		color: #f7901d;
		width: 11.4px;
		height: 14px;
		margin-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1024px) {
	body .remarksHover {
		color: #f7901d;
		width: 15.4px;
		height: 18px;
		cursor: pointer;
		margin-top: 12px;
	}
}
@media screen and (min-width: 1280px) {
	body .remarksHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		cursor: pointer;
		margin-top: 15px;
	}
}
@media screen and (min-width: 1360px) {
	body .remarksHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		cursor: pointer;
		margin-top: 15px;
	}
}
@media screen and (min-width: 1620px) {
	body .remarksHover {
		color: #f7901d;
		width: 30px;
		height: 36px;
		cursor: pointer;
		margin-right: 10px;
		margin-top: 28px;
	}
}

.adminbutton {
	color: #ffffff;
	width: 18.4px;
	height: 18px;
	padding-right: 10px;
	margin-top: 15px;
	cursor: pointer;
	border-right: 1px solid #ffffff40;
}
@media screen and (min-width: 800px) {
	body .adminbutton {
		color: #ffffff;
		width: 11.4px;
		height: 14px;
		margin-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1024px) {
	body .adminbutton {
		color: #ffffff;
		width: 15.4px;
		height: 18px;
		margin-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1280px) {
	body .adminbutton {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		margin-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1360px) {
	body .adminbutton {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		margin-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1620px) {
	body .adminbutton {
		color: #ffffff;
		width: 30px;
		height: 36px;
		margin-top: 28px;
		cursor: pointer;
		margin-right: 10px;
	}
}
.adminButtonHover {
	color: #f7901d;
	width: 18.4px;
	height: 18px;
	padding-right: 10px;
	margin-top: 15px;
	cursor: pointer;
	border-right: 1px solid #ffffff40;
}

@media screen and (min-width: 800px) {
	body .adminButtonHover {
		color: #f7901d;
		width: 11.4px;
		height: 14px;
		margin-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1024px) {
	body .adminButtonHover {
		color: #f7901d;
		width: 15.4px;
		height: 18px;
		margin-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1280px) {
	body .adminButtonHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		margin-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1360px) {
	body .adminButtonHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		margin-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1620px) {
	body .adminButtonHover {
		color: #f7901d;
		width: 30px;
		height: 36px;
		margin-top: 28px;
		cursor: pointer;
		margin-right: 10px;
	}
}

.rightHeader {
	display: flex;
	justify-content: right;
}

.iconsContainer {
	display: flex;
	justify-content: right;
}
.glossaryIcon {
	color: #ffffff;
	width: 16.4px;
	height: 19px;
	padding-top: 15px;
	cursor: pointer;
}

@media screen and (min-width: 800px) {
	body .glossaryIcon {
		color: #ffffff;
		width: 11.4px;
		height: 14px;
		padding-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1024px) {
	body .glossaryIcon {
		color: #ffffff;
		width: 15.4px;
		height: 18px;
		padding-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1280px) {
	body .glossaryIcon {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		padding-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1360px) {
	body .glossaryIcon {
		color: #ffffff;
		width: 16.4px;
		height: 19px;
		padding-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1620px) {
	body .glossaryIcon {
		color: #ffffff;
		width: 30px;
		height: 36px;
		padding-top: 28px;
		cursor: pointer;
		margin-right: 10px;
	}
}

.glossaryIconHover {
	color: #f7901d;
	width: 16.4px;
	height: 19px;
	padding-top: 15px;
	cursor: pointer;
}
@media screen and (min-width: 800px) {
	body .glossaryIconHover {
		color: #f7901d;
		width: 11.4px;
		height: 14px;
		padding-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1024px) {
	body .glossaryIconHover {
		color: #f7901d;
		width: 15.4px;
		height: 18px;
		padding-top: 12px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1280px) {
	body .glossaryIconHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		padding-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1360px) {
	body .glossaryIconHover {
		color: #f7901d;
		width: 16.4px;
		height: 19px;
		padding-top: 15px;
		cursor: pointer;
	}
}
@media screen and (min-width: 1620px) {
	body .glossaryIconHover {
		color: #f7901d;
		width: 30px;
		height: 36px;
		padding-top: 28px;
		cursor: pointer;
		margin-right: 10px;
	}
}

.feedbackIcon {
	color: #ffffff;
	width: 18.01px;
	height: 17.01px;
	border-right: 1px solid #ffffff40;
	padding-right: 15px;
	margin-top: 16px;
}
@media screen and (min-width: 800px) {
	body .feedbackIcon {
		color: #ffffff;
		width: 13.01px;
		height: 12.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 13px;
		margin-top: 13px;
	}
}
@media screen and (min-width: 1024px) {
	body .feedbackIcon {
		color: #ffffff;
		width: 17.01px;
		height: 16.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 15px;
		margin-top: 13px;
	}
}
@media screen and (min-width: 1280px) {
	body .feedbackIcon {
		color: #ffffff;
		width: 18.01px;
		height: 17.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 15px;
		margin-top: 16px;
	}
}
@media screen and (min-width: 1360px) {
	body .feedbackIcon {
		color: #ffffff;
		width: 18.01px;
		height: 17.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 20px;
		margin-top: 16px;
	}
}
@media screen and (min-width: 1620px) {
	body .feedbackIcon {
		color: #ffffff;
		width: 34px;
		height: 32px;
		border-right: 2px solid #ffffff40;
		padding-right: 30px;
		margin-right: 10px;
		margin-top: 30px;
		cursor: pointer;
	}
}

.feedbackIconHover {
	color: #f7901d;
	width: 18.01px;
	height: 17.01px;
	border-right: 1px solid #ffffff40;
	padding-right: 15px;
	margin-top: 16px;
}
@media screen and (min-width: 800px) {
	body .feedbackIconHover {
		color: #f7901d;
		width: 13.01px;
		height: 12.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 13px;
		margin-top: 13px;
	}
}
@media screen and (min-width: 1024px) {
	body .feedbackIconHover {
		color: #f7901d;
		width: 17.01px;
		height: 16.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 15px;
		margin-top: 13px;
	}
}
@media screen and (min-width: 1280px) {
	body .feedbackIconHover {
		color: #f7901d;
		width: 18.01px;
		height: 17.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 15px;
		margin-top: 16px;
	}
}
@media screen and (min-width: 1360px) {
	body .feedbackIconHover {
		color: #f7901d;
		width: 18.01px;
		height: 17.01px;
		border-right: 1px solid #ffffff40;
		padding-right: 20px;
		margin-top: 16px;
	}
}
@media screen and (min-width: 1620px) {
	body .feedbackIconHover {
		color: #f7901d;
		width: 34px;
		height: 32px;
		border-right: 2px solid #ffffff40;
		padding-right: 30px;
		margin-right: 10px;
		margin-top: 30px;
		cursor: pointer;
	}
}

.notificationIcon {
	color: #ffffff;
	width: 22.64px;
	height: 20px;
	cursor: pointer;
	padding-top: 14.5px;
}
@media screen and (min-width: 800px) {
	body .notificationIcon {
		color: #ffffff;
		width: 17.64px;
		height: 14px;
		cursor: pointer;
		padding-top: 11.5px;
	}
}
@media screen and (min-width: 1024px) {
	body .notificationIcon {
		color: #ffffff;
		width: 21.64px;
		height: 19px;
		cursor: pointer;
		padding-top: 11.5px;
	}
}
@media screen and (min-width: 1280px) {
	body .notificationIcon {
		color: #ffffff;
		width: 22.64px;
		height: 20px;
		cursor: pointer;
		padding-top: 14.5px;
	}
}
@media screen and (min-width: 1360px) {
	body .notificationIcon {
		color: #ffffff;
		width: 22.64px;
		height: 20px;
		cursor: pointer;
		padding-top: 14.5px;
	}
}
@media screen and (min-width: 1620px) {
	body .notificationIcon {
		color: #ffffff;
		width: 40px;
		height: 38px;
		cursor: pointer;
		padding-top: 28px;
	}
}

.notificationIconHover {
	color: #f7901d;
	width: 22.64px;
	height: 20px;
	cursor: pointer;
	padding-top: 14.5px;
}
@media screen and (min-width: 800px) {
	body .notificationIconHover {
		color: #f7901d;
		width: 17.64px;
		height: 14px;
		cursor: pointer;
		padding-top: 11.5px;
	}
}
@media screen and (min-width: 1024px) {
	body .notificationIconHover {
		color: #f7901d;
		width: 21.64px;
		height: 19px;
		cursor: pointer;
		padding-top: 11.5px;
	}
}
@media screen and (min-width: 1280px) {
	body .notificationIconHover {
		color: #f7901d;
		width: 22.64px;
		height: 20px;
		cursor: pointer;
		padding-top: 14.5px;
	}
}
@media screen and (min-width: 1360px) {
	body .notificationIconHover {
		color: #f7901d;
		width: 22.64px;
		height: 20px;
		cursor: pointer;
		padding-top: 14.5px;
	}
}
@media screen and (min-width: 1620px) {
	body .notificationIconHover {
		color: #f7901d;
		width: 40px;
		height: 38px;
		cursor: pointer;
		padding-top: 28px;
	}
}

.rightHeaderText {
	display: flex;
	justify-content: right;
	margin-right: 15px;
	padding-bottom: 3px;
	@media screen and (min-width: 800px) {
		margin-right: 11px;
		padding-bottom: 5px;
	}
	@media screen and (min-width: 1024px) {
		margin-right: 12px;
		padding-bottom: 3px;
	}
	@media screen and (min-width: 1280px) {
		display: flex;
		justify-content: right;
		margin-right: 15px;
		padding-bottom: 3px;
	}
	@media screen and (min-width: 1620px) {
		display: flex;
		justify-content: right;
		margin-right: 30px;
		padding-bottom: 6px;
	}
}

.lastUpdated {
	font-family: Segoe UI, sans-serif;
	color: #ffffff98;
	font-size: 11px;
	letter-spacing: 0;
	@media screen and (min-width: 800px) {
		font-size: 8px;
	}
	@media screen and (min-width: 1024px) {
		font-size: 9px;
	}
	@media screen and (min-width: 1280px) {
		font-family: Segoe UI, sans-serif;
		color: #ffffff98;
		font-size: 11px;
		letter-spacing: 0;
	}
	@media screen and (min-width: 1620px) {
		font-family: Segoe UI, sans-serif;
		color: #ffffff98;
		font-size: 18px;
		letter-spacing: 0;
	}
}

.picture {
	width: 22px;
	height: 22px;
	margin-top: 13px;
	border-radius: 50%;
	cursor: pointer;
	@media screen and (min-width: 800px) {
		width: 15px;
		height: 15px;
		margin-top: 11px;
		margin-right: 10px;
		border-radius: 50%;
	}
	@media screen and (min-width: 1024px) {
		width: 20px;
		height: 20px;
		margin-top: 11px;
		margin-right: 13px;
		border-radius: 50%;
		cursor: pointer;
	}
	@media screen and (min-width: 1280px) {
		width: 22px;
		height: 22px;
		margin-top: 13px;
		margin-right: 15px;
		border-radius: 50%;
		cursor: pointer;
	}
	@media screen and (min-width: 1620px) {
		width: 40px;
		height: 40px;
		margin-top: 25px;
		border-radius: 50%;
		cursor: pointer;
		margin-right: 35px;
	}
}
