.autoComplete {
	width: 250px;
}
.modalContainer {
	height: 80vh;
	width: 80vw;
	margin: auto;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	background: #f9f9f9;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.headerContainer {
	background: linear-gradient(155deg, #f8f5f0 0%, #fce3c7 100%, #f7901d 100%);
	padding: 0.5rem;
	flex: 0 0 5%; /* Fixed height of 10% */
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
}

.formContainer {
	display: flex;
	flex: 1; /* Fills the remaining space */
	padding: 1rem;
}

.nameField {
	display: flex;
	justify-content: left;
}
.emailField {
	display: flex;
	justify-content: left;
}

.buttonContainer {
	display: flex;
	justify-content: flex-end;
}

.closeButton {
	font-size: 1rem;
	color: #eb9995;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #eb9995;
	border-radius: 50%;
}
