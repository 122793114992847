body {
  overflow: hidden; /* Hide the default browser scrollbar */
 
}

.scrollable-container {
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: calc(100vh - 64px); /* Adjust based on NavBar height */
  padding-bottom: 5%; /* Optional padding */
  box-sizing: border-box; /* Include padding in height calculations */
}

/* Sidebar styling */
.fixed-sidebar {
  position: fixed;
  top: 73px; /* Adjust based on NavBar height */
  left: 0;
  width: 25%; /* Adjust width for larger screens */
  height: calc(100vh - 65px); /* Full height minus navbar */
  /* Prevent scrolling within the sidebar */
  padding: 16px; /* Optional padding */
  box-sizing: border-box; /* Include padding in height calculations */
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  overflow-y: auto;
 
}

.fixed-sidebar > *:not(:last-child) {
  margin-bottom: 16px; /* Add space between child elements */
}

.fixed-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar in Chrome, Safari, and Opera */
.scrollable-container::-webkit-scrollbar {
  display: none;
}

/* Media Queries for responsiveness */

/* For screens smaller than 600px (mobile) */
@media (max-width: 600px) {
  .fixed-sidebar {
    position: relative;
    width: 100%; /* Full width for small screens */
    height: auto; /* Sidebar collapses on mobile */
    top: auto;
    left: auto;
  }

  .scrollable-container {
    padding-bottom: 10%; /* Adjust bottom padding for small screens */
  }
}

/* For screens between 600px and 960px (tablet) */
@media (min-width: 600px) and (max-width: 960px) {
  .fixed-sidebar {
    position: relative; /* Not fixed, to allow natural scrolling */
    width: 100%; /* Full width on tablet screens */
    height: auto;
    left: 0;
  }
}

/* For larger screens */
@media (min-width: 960px) {
  .fixed-sidebar {
    position: fixed;
    width: 25%; /* Adjusted width for larger screens */
    height: calc(100vh - 64px);
  }

  .scrollable-container {
    padding-bottom: 5%; /* Reset bottom padding for larger screens */
  }
}
