.WindowSize {
	margin-top: 60px;
	width: 1140px;
	height: 561px;
	/* 1920×1080, 1366×768, 1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		margin-top: 42px;
		width: 670px;
		height: 400px;
		margin-left: 165px;
	}
	@media screen and (min-width: 850px) {
		margin-top: 42px;
		width: 730px;
		height: 400px;
		margin-left: 165px;
	}
	@media screen and (min-width: 900px) {
		margin-top: 42px;
		width: 780px;
		height: 400px;
		margin-left: 165px;
	}
	@media screen and (min-width: 950px) {
		margin-top: 42px;
		width: 840px;
		height: 400px;
		margin-left: 165px;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 50px;
		width: 860px;
		height: 560px;
		margin-left: 165px;
	}
	@media screen and (min-width: 1100px) {
		margin-top: 50px;
		width: 920px;
		height: 560px;
		margin-left: 220px;
	}
	@media screen and (min-width: 1150px) {
		margin-top: 50px;
		width: 970px;
		height: 560px;
		margin-left: 220px;
	}
	@media screen and (min-width: 1200px) {
		margin-top: 50px;
		width: 1050px;
		height: 560px;
		margin-left: 220px;
	}
	@media screen and (min-width: 1280px) {
		margin-top: 60px;
		width: 1070px;
		height: 561px;
		margin-left: 220px;
	}
	@media screen and (min-width: 1360px) {
		margin-top: 60px;
		width: 1140px;
		height: 561px;
		margin-left: 220px;
	}
	@media screen and (min-width: 1620px) {
		margin-top: 120px;
		width: calc(100% - 5px);
		height: calc(100vh - 140px);
		margin-left: 337px;
	}
	/* @media screen and (min-width: 1920px) {
		margin-top: 120px;
		width: 1700px;
		height: 930px;
	}
	@media screen and (min-width: 2200px) {
		margin-top: 120px;
		width: 90vw;
		height: 95vh;
	} */
}
.PowerBiReportFull {
	margin-top: 60px;
	width: 98vw;
	height: 89vh;
}

.closeButton {
	font-size: 14px;
	color: #eb9995;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #eb9995;
	border-radius: 50%;
}

.Table th,
.Table td {
	height: 20px;
	overflow: hidden;
	width: fit-content;
}

.Table input {
	height: 20px;
	width: fit-content;
}
