.body {
	background-color: #f3f3f3;
	margin: 0px 20px 10px 20px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.buttonContainer {
	padding: 40px 0px 0px 0px;
}
.closeIcon {
	color: #ea3c3c;
	border: 2px solid #ea3c3c;
	border-radius: 50%;
	margin-top: 10px;
	margin-bottom: 10px;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.message {
	font-weight: 800;
	font-size: 1rem;
	padding: 5px;
	margin: 5px;
	opacity: 0.8;
}

@media screen and (min-width: 800px) {
	body .message {
		font-size: 0.9rem;
	}
}
@media screen and (min-width: 1024px) {
	body .message {
		font-size: 0.9rem;
	}
}
@media screen and (min-width: 1280px) {
	body .message {
		font-size: 1rem;
	}
}
@media screen and (min-width: 1360px) {
	body .message {
		font-size: 1rem;
	}
}
@media screen and (min-width: 1620px) {
	body .message {
		font-size: 1.3rem;
	}
}

.warning {
	font-weight: 800;
	font-size: 0.8rem;
	padding: 5px;
	margin: 5px;
	opacity: 0.5;
}
@media screen and (min-width: 800px) {
	body .warning {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1024px) {
	body .warning {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1280px) {
	body .warning {
		font-size: 0.8rem;
	}
}
@media screen and (min-width: 1360px) {
	body .warning {
		font-size: 0.8rem;
	}
}
@media screen and (min-width: 1620px) {
	body .warning {
		font-size: 1rem;
	}
}

.button {
	background-color: #ea3c3c;
	color: #ffffff;
	margin: 10px;
	font-size: 0.7rem;
	text-align: center;
	letter-spacing: 1px;
}
@media screen and (min-width: 800px) {
	body .button {
		font-size: 0.6rem;
	}
}
@media screen and (min-width: 1024px) {
	body .button {
		font-size: 0.6rem;
	}
}
@media screen and (min-width: 1280px) {
	body .button {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1360px) {
	body .button {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1620px) {
	body .button {
		font-size: 0.9rem;
	}
}

.closeButton {
	background-color: #5d5957;
	opacity: 0.4;
	color: #ffffff;
	margin: 10px;
	font-size: 0.7rem;
	text-align: center;
	letter-spacing: 1px;
}

@media screen and (min-width: 800px) {
	body .closeButton {
		font-size: 0.6rem;
	}
}
@media screen and (min-width: 1024px) {
	body .closeButton {
		font-size: 0.6rem;
	}
}
@media screen and (min-width: 1280px) {
	body .closeButton {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1360px) {
	body .closeButton {
		font-size: 0.7rem;
	}
}
@media screen and (min-width: 1620px) {
	body .closeButton {
		font-size: 0.9rem;
	}
}
