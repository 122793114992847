.Error {
	width: 1140px;
	height: 561px;
	background-color: white;
	/* 1920×1080, 1366×768, 1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		width: 670px;
		height: 400px;
	}
	@media screen and (min-width: 850px) {
		width: 730px;
		height: 400px;
	}
	@media screen and (min-width: 900px) {
		width: 780px;
		height: 400px;
	}
	@media screen and (min-width: 950px) {
		width: 840px;
		height: 400px;
	}
	@media screen and (min-width: 1024px) {
		width: 860px;
		height: 560px;
	}
	@media screen and (min-width: 1100px) {
		width: 920px;
		height: 560px;
	}
	@media screen and (min-width: 1150px) {
		width: 970px;
		height: 560px;
	}
	@media screen and (min-width: 1200px) {
		width: 1050px;
		height: 560px;
	}
	@media screen and (min-width: 1280px) {
		width: 1070px;
		height: 561px;
	}
	@media screen and (min-width: 1360px) {
		width: 1140px;
		height: 561px;
	}
	@media screen and (min-width: 1620px) {
		width: calc(100% - 5px);
		height: calc(100vh - 140px);
	}
}

.errorBox {
	display: grid;
	place-items: center;
}

.oopsText {
	font-family: 'Segoe UI';
	font-weight: bold;
	font-size: 20px;
	color: #312e2d;
}

.errorMessage {
	font-family: 'Segoe UI';
	font-size: 12px;
	color: #707070;
	margin-top: 15px;
}

.refreshButton {
	width: 78px;
	height: 30px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Segoe UI';
	background-color: #f7901d;
	border-radius: 3px;
	color: white;
	cursor: pointer;
	margin-top: 10px;
}
