.app {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	height: 100vh;
	/* background-color: #fd9327; */
	background-color: #312e2d;
	letter-spacing: 1px;
}

.loginForm {
	background-color: #ffffff;
	height: 292px;
	width: 405px;
}
.signInBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 35px;
}

.signInButton {
	border: 1px solid #dddddd;
	border-radius: 2px;
	width: 300px;
	height: 35px;
}

.signInButton:hover {
	color: #f7901d;
	border-color: #f7901d;
}

.buttonText {
	font-family: 'Segoe UI';
	font-size: 14px;
	color: #312e2d;
	text-transform: none;
	margin-left: 10px;
	letter-spacing: 0px;
}

.errMessage {
	color: red;
	font-family: 'Segoe UI';
}

.onedataicon {
	width: 96px;
	height: 79.39px;
	margin-top: 40px;
}

.googleLogo {
	width: 13.75px;
	height: 13.75px;
}

.tigerLogo {
	width: 63px;
	height: 26.11px;
}

.copyRight {
	font-family: 'Segoe UI';
	color: #a8a8a8;
	font-size: 10px;
	letter-spacing: 0px;
}

.footContainer {
	margin-right: 10px;
}
