.addUserButton {
	background-color: #f7901d;
	border-radius: 3px;
	padding: 5px;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
	max-height: 30px;
	min-width: 200px;
}

.right {
	display: flex;
	justify-content: right;
	align-items: center;
	text-align: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.spacearound {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
