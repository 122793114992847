.Menu {
	margin-top: 25px;
	padding: 0px;
	@media screen and (min-width: 800px) {
		margin-top: 25px;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 25px;
	}
	@media screen and (min-width: 1280px) {
		margin-top: 25px;
	}
	@media screen and (min-width: 1360px) {
		margin-top: 25px;
	}

	@media screen and (min-width: 1620px) {
		margin-top: 55px;
	}
}

.MenuItem {
	padding-top: 0px;
	padding-bottom: 0px;
	color: #f7901d;
	font-size: 12px;
	@media screen and (min-width: 800px) {
		font-size: 10px;
	}
	@media screen and (min-width: 1024px) {
		font-size: 12px;
	}
	@media screen and (min-width: 1280px) {
		font-size: 14px;
	}
	@media screen and (min-width: 1360px) {
		font-size: 14px;
	}

	@media screen and (min-width: 1620px) {
		font-size: 18px;
	}
}
