/* Main container that holds the entire app */
.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* Content container holds both the filter and table */
.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px; /* Optional: Add margin-top for spacing */
}
.MuiBox-root .css-xrgx1o{
  border: none; /* Remove borders */
  box-shadow: none; /* Remove shadow */
  padding: 0; /* Remove any padding */
  margin: 0; /* Remove any margin */
 }

/* Filter container styles */
.filter-container {
  padding: 6px;
  border-radius:none;
  margin-bottom: 2px; /* Space between the filter and table */
}

/* Table container styles */

.table-container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.snackbar-alert {
  width: 100%;
  border-radius: 8px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  text-align: center;
  padding: 10px 20px;
}

.snackbar-alert .MuiAlert-icon {
  font-size: 22px;
}

.table-scroll-container {
  flex-grow: 1;
  max-height: calc(100vh - 180px);
  display: block;
  overflow-y: auto;
  padding: 20;
  
}
.main-container .table-scroll-container {
  border: none !important;
  box-shadow: none !important;
}

.bottom-buttons {
  position: fixed;
  bottom: 2px;
  right: 0.77%;
  display: flex;
  gap: 5px;
}


.save-button {
  padding: 4px 8px !important; /* Increased padding for bigger button */
  font-size: 0.8rem !important; /* Larger font size */
  font-family: "Nanum Myeongjo", serif !important;
  border-color: orange !important;
  color: orange !important;
  border-width: 2px !important; /* Slightly thicker border for more impact */
  border-style: solid !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important; /* Added transition for hover effect */
  
}


.save-button:hover {
  border-color: #ff8c00 !important;
  background-color: #fff0e0 !important;
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.save-button:active {
  transform: scale(1.05);
}

.submit-button {
  padding: 4px 8px !important ;
  font-size: 0.8rem !important;
  font-family: "Nanum Myeongjo", serif !important;
  background-color: orange !important;
  color: white !important;
  border-width: 2px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.submit-button:hover {
  background-color: #ff8c00;
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.submit-button:active {
  transform: scale(1.05);
}

.dialog-container .MuiPaper-root {
  border-radius: 12px;
  padding: 16px;
}

.dialog-title {
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #1976d2;
  padding-bottom: 12px;
}

.dialog-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 4px 0;
}

.column-option {
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.column-name {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-actions {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;

}

.close-button {
  align-items: center;
  text-transform: none;
  padding: 10px 24px;
  font-weight: 600;
  font-size: 1rem;
  background-color: #1976d2;
}

.close-button:hover {
  background-color: #1565c0;
}

.filter-menu {
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  max-width: 240px;
  max-height: 300px;
  overflow-y: auto;
  transition: max-height 0.3s ease-out, opacity 0.3s ease;
  opacity: 1;
}

.filter-menu-title {
  padding: 8px 12px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.filter-menu-divider {
  margin: 8px 0;
}

.filter-menu-item {
  padding: 8px 16px;
  text-align: center;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.filter-menu-item:hover {
  background-color: #f0f0f0;
}
