.twoWayResponseBody {
	padding-top: 45px;
	@media screen and (min-width: 800px) {
		padding-top: 35px;
	}
	@media screen and (min-width: 1024px) {
		padding-top: 40px;
	}
	@media screen and (min-width: 1280px) {
		padding-top: 45px;
	}
	@media screen and (min-width: 1360px) {
		padding-top: 45px;
	}

	@media screen and (min-width: 1620px) {
		padding-top: 120px;
	}
}
