/* PmsFileUpload.css */

.file-upload-container {
    padding: 40px;
    max-width: 100%;
    font-family: "Nanum Myeongjo", serif;
    

  }
  
  .header {
    position: relative;
    top: -30px; /* Adjust the value to your preference */
  align-items: center;
  
    
  }

  .format-button{
    position: absolute;
    top: 62px;
    right: 20px;
    padding: 10px 20px;
   
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .header h1 {
    font-weight: bold;
    color: #F7901D;
   
  }
  

  .header-buttons {
    position: absolute;
    top: 0;
    right: 0;
    
  }
  
  /* Additional styling for the Go Back button */
  .back-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
  }
  

  .header-buttons button {
    padding: 10px 20px;
    cursor: pointer;
  }
 

  .content {
    position: relative;
    top: -40px; /* Increase the negative value to move the content further up */
    gap: 50px;
  }
  
  .file-list {
   
    max-height: 320px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 50%; /* Keeps the width at 25% of the parent container */
    height: calc(100vh - 64px); 
   
  }
  
  .file-lists {
   
    max-height: 355px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 33%; /* Keeps the width at 25% of the parent container */
    height: calc(100vh - 64px); 
   
  }
  .file-list h2{
    color: #ff5733; /* Change to your desired color */
    background-color: #f0f0f0; /* Optional: add a background color */
    text-shadow: #f0f0f0;
  }
  
  .file-list ul {
    list-style: none;
    padding: 0;
  }
  
  .file-item {
    padding: 5px;
    cursor: pointer;
    margin-bottom: 6px !important;
    background: #fff;
    border-radius: 4px;
  }
  
  .file-item:hover {
    background-color: #FFB84D;
  }
  
  .file-item.selected {
    background-color: #d41402;
  }
  
  
  .file-dropzone {
    border: 2px dashed #F7901D;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    background: #fff;
  }
  .notes-zone{
    max-height: 355px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 33%; /* Keeps the width at 25% of the parent container */
    height: calc(100vh - 64px); 
   

  }
  .notes-zone ul{
    
      list-style: none;
      padding: 0;
      gap: 100px;
      text-align: left;
      padding: 10px;
    
  }
  .notes-zone ul li {
    margin-bottom: 20px; /* Add space between each list item */
  }
  
  .upload-icon {
    font-size: 40px;
    color: #F7901D;
  }
  
  .browse-text {
    text-decoration: underline;
    color: #F7901D;
    cursor: pointer;
  }
  
  .file-info {
    margin-bottom: 20px;
    font-weight: bold;
    color: black;
  }
  
  .upload-button {
    width: 100%;
    padding: 10px;
    background-color: #F7901D;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .upload-button:hover {
    background-color: #FFB84D;
  }
  
  .alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .alert.error {
    background-color: red;
  }
/* Add this CSS for the loading indicator */
.loading-indicator {
    text-align: center;
    font-weight: bold;
    color: #F7901D;
    margin-top: 20px;
  }
/* Style for format button */
.header-buttons {
    display: flex;
    gap: 10px;
  }
  
  .format-button {
    font-size: 16px;
    background-color: #f7901d;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .format-button:hover {
    background-color: #ffb84d;
  }
  
  /* Format Dialog Styles */
  .format-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease;
  }
  
  .format-dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    animation: slideIn 0.3s ease;
  }
  
  .format-dialog h2 {
    margin-top: 0;
    color: #f7901d;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  table th,
  table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .close-dialog-button {
    margin-top: 15px;
    background-color: #f7901d;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-dialog-button:hover {
    background-color: #ffb84d;
  }
  .highlight {
    font-weight: bold;
    color: #ff5733; /* Change to your desired color */
    background-color: #f0f0f0; /* Optional: add a background color */
  }
  
  /* Animation for dialog */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(-20px); }
    to { transform: translateY(0); }
  }
  