.body {
	padding-top: 60px;
	/* 1920×1080, 1366×768, 1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		padding-top: 42px;
	}
	@media screen and (min-width: 1024px) {
		padding-top: 50px;
	}
	@media screen and (min-width: 1280px) {
		padding-top: 60px;
	}
	@media screen and (min-width: 1360px) {
		padding-top: 60px;
	}
	@media screen and (min-width: 1620px) {
		padding-top: 120px;
	}
	@media screen and (min-width: 1920px) {
		padding-top: 120px;
	}
	@media screen and (min-width: 2200px) {
		padding-top: 120px;
	}
}
