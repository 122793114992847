.container {
	background-color: white;
	height: 75vh;
	width: 75vw;
	border: 1px solid #f7901d;
	border-radius: 1rem;
	padding: 1rem;
}

.header {
	background: linear-gradient(155deg, #f8f5f0 0%, #fce3c7 100%, #f7901d 100%);
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 0.5rem;
	color: #444444;
}

.leftHeader {
	font-weight: bold;
}

.closeButton {
	font-size: 1rem;
	color: #eb9995;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #eb9995;
	border-radius: 50%;
}
.UserList {
	overflow: auto;
	max-height: 70vh;
	background: #ffffff;
}

.UserList::-webkit-scrollbar {
	width: 6px;
}

.UserList::-webkit-scrollbar-track {
	background-color: #f4f4f4;
}

.UserList::-webkit-scrollbar-thumb {
	background: linear-gradient(180deg, #fce3c7 100%, #f7901d 100%);
	border-radius: 1px;
}

.singleElementStyle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: linear-gradient(170deg, #f8f5f0 0%, #fce8d1 50%, #fca654 100%);
	padding: 0.5rem;
	margin: 0.5rem;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 0.5rem;
	border-bottom: 1px solid #ddd;
	cursor: pointer;
	color: #555555;
}
