.main {
	border-top: 1px solid #ffffff1a;
	min-height: 120px;
	@media screen and (min-width: 800px) {
		border-top: 2px solid #ffffff1a;
		min-height: 75px;
	}
	@media screen and (min-width: 1024px) {
		border-top: 1px solid #ffffff1a;
		min-height: 90px;
	}
	@media screen and (min-width: 1280px) {
		border-top: 1px solid #ffffff1a;
		min-height: 120px;
	}
	@media screen and (min-width: 1360px) {
		border-top: 1px solid #ffffff1a;
		min-height: 120px;
	}
	@media screen and (min-width: 1620px) {
		border-top: 2px solid #ffffff1a;
		min-height: 205px;
	}
	@media screen and (min-width: 1920px) {
		border-top: 2px solid #ffffff1a;
		min-height: 205px;
	}
}
.lastMain {
	border-top: 1px solid #ffffff1a;
	border-bottom: 1px solid #ffffff1a;
	min-height: 120px;
	@media screen and (min-width: 800px) {
		border-top: 2px solid #ffffff1a;
		min-height: 75px;
	}
	@media screen and (min-width: 1024px) {
		border-top: 1px solid #ffffff1a;
		border-bottom: 1px solid #ffffff1a;
		min-height: 90px;
	}
	@media screen and (min-width: 1280px) {
		border-top: 1px solid #ffffff1a;
		border-bottom: 1px solid #ffffff1a;
		min-height: 120px;
	}
	@media screen and (min-width: 1360px) {
		border-top: 1px solid #ffffff1a;
		border-bottom: 1px solid #ffffff1a;
		min-height: 120px;
	}
	@media screen and (min-width: 1620px) {
		border-top: 2px solid #ffffff1a;
		border-bottom: 2px solid #ffffff1a;
		min-height: 205px;
	}
}

.left {
	display: flex;
	flex-flow: column;
	justify-content: center;
	border-right: 1px solid #ffffff1a;
	@media screen and (min-width: 800px) {
		border-right: 2px solid #ffffff1a;
	}
	@media screen and (min-width: 1024px) {
		border-right: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1280px) {
		border-right: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1360px) {
		border-right: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1620px) {
		border-right: 2px solid #ffffff1a;
	}
}

.selectedLeft {
	display: flex;
	flex-flow: column;
	justify-content: center;
	border-right: 2px solid #f7901d;
	width: 67px;
	@media screen and (min-width: 800px) {
		border-right: 2px solid #ffffff1a;
	}
	@media screen and (min-width: 1024px) {
		border-right: 2px solid #f7901d;
	}
	@media screen and (min-width: 1280px) {
		border-right: 2px solid #f7901d;
	}
	@media screen and (min-width: 1360px) {
		border-right: 2px solid #f7901d;
	}
	@media screen and (min-width: 1620px) {
		border-right: 2px solid #f7901d;
	}
}

.right {
	display: flex;
	flex-direction: column;
}

.title {
	display: flex;
	justify-content: center;
	transform: rotate(-90deg);
	color: #ffffff;
}

.selectedtitle {
	color: #f7901d;
	display: flex;
	justify-content: center;
	transform: rotate(-90deg);
}
.titleText {
	font-family: 'Segoe UI';
	font-size: 14px;
	@media screen and (min-width: 800px) {
		font-size: 11px;
	}
	@media screen and (min-width: 1024px) {
		font-family: 'Segoe UI';
		font-size: 12px;
	}
	@media screen and (min-width: 1280px) {
		font-family: 'Segoe UI';
		font-size: 14px;
	}
	@media screen and (min-width: 1360px) {
		font-family: 'Segoe UI';
		font-size: 14px;
	}
	@media screen and (min-width: 1620px) {
		font-size: 18px;
	}
}

.report {
	margin: 8px 0px 0px 14px;
	padding-bottom: 6px;
	border-bottom: 1px solid #ffffff1a;
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	@media screen and (min-width: 800px) {
		margin: 5px 0px 0px 10px;
		padding-bottom: 3px;
		border-bottom: 2px solid #ffffff1a;
	}
	@media screen and (min-width: 1024px) {
		margin: 6px 0px 0px 11px;
		padding-bottom: 4px;
		border-bottom: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1280px) {
		margin: 8px 0px 0px 14px;
		padding-bottom: 6px;
		border-bottom: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1360px) {
		margin: 8px 0px 0px 14px;
		padding-bottom: 6px;
		border-bottom: 1px solid #ffffff1a;
	}
	@media screen and (min-width: 1620px) {
		margin: 12px 0px 0px 18px;
		padding-bottom: 10px;
		border-bottom: 2px solid #ffffff1a;
	}
}

.selectedReport {
	padding: 8px 0px 6px 14px;
	border-bottom: 1px solid #ffffff1a;
	display: flex;
	justify-content: flex-start;
	color: #ffffff;
	cursor: pointer;
	background-color: #f7901d;
	@media screen and (min-width: 800px) {
		padding: 5px 0px 3px 10px;
		border-bottom: 2px solid #ffffff1a;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1024px) {
		padding: 6px 0px 4px 11px;
		border-bottom: 1px solid #ffffff1a;
		color: #ffffff;
		cursor: pointer;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1280px) {
		padding: 8px 0px 6px 14px;
		border-bottom: 1px solid #ffffff1a;
		color: #ffffff;
		cursor: pointer;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1360px) {
		padding: 8px 0px 6px 14px;
		border-bottom: 1px solid #ffffff1a;
		color: #ffffff;
		cursor: pointer;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1620px) {
		padding: 12px 0px 10px 18px;
		border-bottom: 2px solid #ffffff1a;
		background-color: #f7901d;
	}
}

.lastreport {
	margin: 8px 0px 0px 14px;
	padding-bottom: 6px;
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	padding-bottom: 10px;
	padding-top: 10px;
	@media screen and (min-width: 800px) {
		margin: 5px 0px 0px 10px;
		padding-bottom: 3px;
		padding-top: 8px;
	}
	@media screen and (min-width: 1024px) {
		margin: 6px 0px 0px 11px;
		padding-bottom: 6px;
		padding-bottom: 4px;
		padding-top: 10px;
	}
	@media screen and (min-width: 1280px) {
		margin: 8px 0px 0px 14px;
		padding-bottom: 6px;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	@media screen and (min-width: 1360px) {
		margin: 8px 0px 0px 14px;
		padding-bottom: 6px;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	@media screen and (min-width: 1620px) {
		margin: 12px 0px 0px 18px;
		padding-bottom: 10px;
		padding-top: 14px;
	}
}

.lastselectedReport {
	margin: 8px 0px 0px 0px;
	padding-left: 14px;
	display: flex;
	justify-content: flex-start;
	color: #ffffff;
	cursor: pointer;
	padding-bottom: 10px;
	padding-top: 10px;
	background-color: #f7901d;
	@media screen and (min-width: 800px) {
		margin: 5px 0px 0px 10px;
		padding-bottom: 3px;
		padding-top: 8px;
	}
	@media screen and (min-width: 1024px) {
		margin: 6px 0px 0px 11px;
		padding-left: 14px;
		color: #ffffff;
		padding-bottom: 4px;
		padding-top: 10px;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1280px) {
		margin: 8px 0px 0px 0px;
		padding-left: 14px;
		color: #ffffff;
		padding-bottom: 10px;
		padding-top: 10px;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1360px) {
		margin: 8px 0px 0px 0px;
		padding-left: 14px;
		color: #ffffff;
		padding-bottom: 10px;
		padding-top: 10px;
		background-color: #f7901d;
	}
	@media screen and (min-width: 1620px) {
		margin: 12px 0px 0px 18px;
		padding-bottom: 10px;
		padding-top: 14px;
	}
}

.reportfontStyle {
	font-family: 'Segoe UI';
	color: #ffffff;
	font-size: 12px;
	@media screen and (min-width: 800px) {
		font-size: 8.5px;
	}
	@media screen and (min-width: 1024px) {
		font-size: 9.5px;
	}
	@media screen and (min-width: 1280px) {
		font-family: 'Segoe UI';
		color: #ffffff;
		font-size: 12px;
	}
	@media screen and (min-width: 1360px) {
		font-family: 'Segoe UI';
		color: #ffffff;
		font-size: 12px;
	}
	@media screen and (min-width: 1620px) {
		font-size: 16px;
	}
}

.extraReports {
	background-color: #ffffff10;
	height: 11px;
	display: flex;
	justify-content: center;
	@media screen and (min-width: 800px) {
		height: 12px;
	}
	@media screen and (min-width: 1024px) {
		height: 13px;
	}
	@media screen and (min-width: 1280px) {
		background-color: #ffffff10;
		height: 11px;
	}
	@media screen and (min-width: 1360px) {
		background-color: #ffffff10;
		height: 11px;
	}
	@media screen and (min-width: 1620px) {
		height: 15px;
	}
}

.extraReportText {
	font-size: 9px;
	color: #f7901d;
	@media screen and (min-width: 800px) {
		font-size: 8.5px;
	}
	@media screen and (min-width: 1024px) {
		font-size: 9px;
	}
	@media screen and (min-width: 1280px) {
		font-size: 9px;
	}
	@media screen and (min-width: 1360px) {
		font-size: 9px;
	}
	@media screen and (min-width: 1620px) {
		font-size: 12px;
	}
}

.customScroll {
	max-height: 300px;
	/* Set a maximum height or adjust as needed */
	overflow-y: auto;
	scrollbar-width: thin;
	/* Firefox */
	scrollbar-color: darkgray lightgray;
	/* Firefox */
}

.customScroll::-webkit-scrollbar {
	width: 6px;
}

.customScroll::-webkit-scrollbar-thumb {
	background-color: darkgray;
	border-radius: 6px;
}

.customScroll::-webkit-scrollbar-track {
	background-color: lightgray;
}
